/* ReactForm form-based table description helpers */
import React from 'react';
import { ReactForm, ReactInlineForm, BaseForm, formFromTarget, dependencies_true } from './reactform';
import MultiTemplated from './multitemplated';
import { makeStyles } from '@material-ui/core/styles';
import { with_focus } from 'dash/focusprovider';
import MuiLoader from 'dash/MuiLoader';
import { merge_column } from 'atxtable/mergecolumns';
import { requiring_tabular_storage } from 'storages';
import generic_filter_rows from './genericfilter';
import { dropdown_filter_rows } from 'reactform/dropdownFilter';
import { quick_settings_form } from 'reactform/quicksetting';
import './reactformtable.css';
import { type_renderer, render_value } from './typerenderer';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ActionMenu from 'reactform/actionmenu';
import ATXTable from 'atxtable';
import TreeNameRenderer from './treenamerender';
import PropTypes from 'prop-types';
import WithCurrentUser from 'withcurrentuser';
import redirect from 'redirect';
import { withRouter } from 'react-router';
import {DateAndTimePickers, customTimeRangeFilter } from './datetimefilter';
import Popper from '@material-ui/core/Popper';
import { ClickAwayListener } from '@material-ui/core';


const TREE_INDENT = 16;
const styles = (theme) => ({
    clickableRow: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
        cursor: 'pointer',
    },
    focussed: {
        backgroundColor: '#D5F5E3 !important',
    },
    header: {
        backgroundColor: theme.roles.headers.table,
    },
    firstField: {
        borderLeft: 'thin solid #e0e0e0',
    },
    quick_setting_trigger: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    relativePosition: {
        position: 'relative',
    },
    defaultAlignment: {
        alignContent: 'start',
        justifyContent: 'center',
    },
    '& disabled': {
        color: theme.roles.disabled,
    }
});

const TreeParentRenderer = (Implementation) => {
    class WithParentRenderer extends React.Component {
        render() {
            return null;
        }
    }
    return WithParentRenderer;
};

function DateTimeFilterPopper({column: { setFilter}}) {
    
    const useStyles = makeStyles((theme) => ({
        button: {
          backgroundColor: theme.palette.background.paper,
          border: 'none'
        },
        popper: {
          marginTop: '5px',
          background: '#fff'
        }
      }));

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isFilterApplied, setFilterApplied] = React.useState(false);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClosePopper = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const buttonId = open ? 'simple-popper' : undefined;

    const handleClickAway = () => {
        setAnchorEl(null);
    }
    
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <button 
                    className={classes.button} 
                    aria-describedby={buttonId} 
                    type="button" 
                    onClick={handleClick}
                >
                { isFilterApplied ? 'Filter Applied' : 'Filter' }
                </button>

                { open ? (
                <Popper className={classes.popper} id={buttonId} open={open} anchorEl={anchorEl}>
                <DateAndTimePickers
                    setFilter={setFilter}
                    handleClosePopper={handleClosePopper}
                    isFilterApplied={isFilterApplied}
                    setFilterApplied={setFilterApplied}
                />
                </Popper>
                ) : null }
            </div>
        </ClickAwayListener>
    );
  }


class ManualReactFormTable extends React.Component {
    /* Form-api stand-in that handles N forms in a table

    This base class handles just the rendering and interactions,
    the BaseReactFormTable adds styling and current-user access.
    ReactFormTable includes loading the forms from a form-storage
    and resolving the overall focus of the table.

    props:
        form_keys -- set of form templates to load (MultiTemplated)
        form_details -- set of forms loaded by MultiTemplated from form_keys *or*
            pre-computed values for the same...
        widgets -- override widgets for individual field names
            {field_name: ({value,record,field,alignment,classes}) => <div />}
        editable -- if True, provide editor forms for the rows
        editing_forms -- {key: form_key} mapping to override what form to use to 
                         edit a given record (vs display)
        wrap_data( record, table ) -- convert a data-record to a
            set of form instances indexed by `form_keys`;
            return an array of `form_details` structures
            table.as_form(record, index) will use the `form_details`
            structure as templates to construct individual forms.
        column_callback( columns, table ) -- given the default columns
            we would create for the table, add any extra columns you
            want to add to the table
        link_to_edit -- if true, load target URL even if we have an editing form
        link_function -- (target, form, this) => url-to-load
        overall_target -- context, normally the parent record whose linked records 
            are represented by this tabular query
        
        cell_wrapper -- ({record,field,base,table}) => wrap a field (or not) to apply table-level
                        features such as filtering guis or the like...

    state:
        forms: {
            record_key: [ form, form, form ],
        },
        changed: bool
    */
    static defaultProps = {
        ...BaseForm.defaultProps,
        form_details: {},
        form_keys: [],
        columns: null, // key: {...} overrides for individual columns...
        editable: true,
        editing_forms: {}, // key: key for overriding the editing form for a display-form key
        inPlaceEditing: false,
        inPlaceButtons: ["cancel", "delete", "save"],
        widgets: null,
        record_key: (record, form_index) => `${form_index}.${record.id || record.pk}`,
        form_titles: [],
        current_focus: null, // record/object which is currently focussed
        title_only_forms: {},
        onForm: null, // create (pop-up) ReactForm for the given form properties
        extra_fields: null, // {form_key: [{field}, {field}]}
        quick_settings: {
            // 'field_name': 'form_key'
        },
        column_widths: null, // { field_name: 'tiny','small','short','medium','wide' }

        hierarchy_fields: null, // {parent: parent_name, name: local_name, tree: tree_name }
        link_function: null, // (target, form, this) => {}
        link_to_edit: false, // if true, do link-to-edit even if we have a local form
        overall_target: {},
        column_callback: (columns, table) => columns,
        wrap_data: (data_record, table) => {
            return [data_record];
        },
        preference_key: null, // Key to lookup defaults on user preferences
        preference_default: null, // default values for initial user preferences
        data: [],
        defaultSorting: null, // [{id:'field',desc:false}]
        cellDecorator: null, // (Implementation, table, field, form_index) => decorated implementation
        pageSize: 200, // page size for pagination
        downloads: false,
        useServersideFiltering: false,
        total_records : 0,
    };
    rows = [];

    state = {
        'form_target': null,
        'form_details': null,
        'inplace_target': null, // {target: row, field: }
        'columns': null,
    };
    constructor(props) {
        super(props);
        this.table = null;
    }
    componentDidMount() {
        this.setState({ 'columns': this.columns() });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.field_order !== this.props.field_order) {
            this.setState({ 'columns': this.columns() });
        }
    }
    get_form_key(record, index) {
        /* Get the form-key for the given record */
        const key = (index < this.props.form_keys.length && this.props.form_keys[index]) || record.__type__ || record.type;
        return key;
    }
    get_form_index = (key) => {
        return this.props.form_keys.findIndex(r => r === key);
    };
    get_form_details(record, index) {
        if (index === null || index === undefined) {
            return null;
        }
        const key = self.get_form_key(record, index);
        return this.props.form_details[key];
    }
    as_form(record, index) {
        let key = this.get_form_key(record, index);
        key = this.props.editing_forms[key] || key;

        const template = this.props.form_details[key];
        if (template) {
            return {
                ...formFromTarget(record, template),
                form_key: key,
            };
        } else {
            return null;
        }
    }
    on_inline_blur = (evt, value) => {
        // TODO: if we have unsaved/failed changes, abort the set state
        this.setState({ inplace_target: null });
    };
    on_inline_update = (instance, record, index) => {
        console.log(`Inline updated record`);
        record[index] = instance;
        this.setState({});
    };
    as_form_inline(record, index, field, clicked = true) {
        const key = this.get_form_key(record, index);
        const template = this.props.form_details[key];
        if (template) {
            const base = this.as_form(record, index);
            return <ReactInlineForm
                form_details={base}
                debug={true}
                target={record}
                field={field.name}
                onBlur={this.on_inline_blur}
                onChange={(instance) => {
                    this.on_inline_update(instance, record, index);
                }}
                initialClick={clicked}
            />;
        } else {
            return null;
        }
    }
    getTrProps(state, rowInfo, column, instance) {
        const { classes, user, current_focus } = this.props;
        const editable = rowInfo.original.some(this.is_editable);
        let extra_classes = [];
        if (current_focus) {
            if (rowInfo.original.filter(r => (r && r.__key__) == current_focus.__key__).length) {
                extra_classes = [
                    ...extra_classes,
                    classes.focussed
                ];
            }
        }
        if (editable) {
            extra_classes = [...extra_classes, classes.clickableRow, 'clickable'];
        }
        if (rowInfo.original.is_superuser) {
            extra_classes = [...extra_classes, 'factory-only'];
        }
        return {
            className: classNames(...extra_classes),
        };
    }
    getTheadGroupProps(state, rowInfo, column, instance) {
        const { classes, editable } = this.props;
        return {
            className: classNames(classes.header),
        };
    }
    default_edit_start({ target, form, table }) {
        const url = target.__url__ || target.url;
        if (url && (this.props.link_to_edit || (!form))) {
            if (this.props.link_only) {
                redirect(url, this.props.history);
            } else {
                redirect(url, this.props.history);
            }
        } else {
            this.setState({
                'form_target': target,
                // 'form_details': form,
            });
        }
    }
    inplace_editing(test_target, test_column) {
        /* Are we currently editing this column for this target */
        if (!this.state.inplace_target) {
            return false;
        }
        const { target, column } = this.state.inplace_target;
        // console.log(`Test: ${test_target.__type__} ${test_target.__pk__ || test_target.id} for ${test_column.id}`);
        // console.log(` For: ${target.__type__} ${target.__pk__ || target.id} for ${column.id}`);
        if (
            target && test_target &&
            target.__type__ === test_target.__type__ &&
            (target.__pk__ && target.__pk__ == test_target.__pk__) &&
            (target.id && target.id == test_target.id)
        ) {
            if (test_column.id === column.id) {
                // console.log(` Matched`);
                return true;
            }
        }
        return false;
    }
    is_editable = (target) => {
        /* does it seem likely we can edit this target?

        Note: this is *not* what constrains the action, it is merely
        avoiding annoying situations where you edit the record in a
        form only to find you don't have write permissions.
        */
        const { user } = this.props;
        if (target === null || target === undefined) {
            return false;
        }
        return this.props.editable && (
            (target.editable === undefined) ||
            (target.editable === true)
        ) && (
                (target.permission === undefined || target.permission === null) ||
                (user && user.has_permission(target.permission))
            ) && (
                (!this.props.permission) ||
                (user && user.has_permission(this.props.permission))
            );
    };
    column_editable = (column) => {
        if (column.type_name && column.type_name === 'DisplayWidget') {
            return false;
        }
        return true;
    };
    getTdProps(state, rowInfo, column, instance) {
        /* Get the TD props for the given column/instance */
        const { classes, user, inPlaceEditing, link_only, link_to_edit } = this.props;


        const target = rowInfo.original[column.form_index];
        const editable = this.is_editable(target) && this.column_editable(column);
        const props = {};
        const form_field = column.form_field;
        if (column.onClick) {
            props.onClick = (e, originalCallback) => {
                return column.onClick({
                    'target': target,
                    'table': this,
                    'column': column,
                });
                // return originalCallback();
            };
            props.title = column.title || 'Show per-row actions';
        } else if (editable) {
            if (inPlaceEditing) {
                // Get a single form-widget and hook up on-change to update it...
                if (this.inplace_editing(
                    target, column,
                )) {
                    // props.tabIndex = 0;
                    // we are editing this field, don't hook on-click for it...
                } else {
                    const click_tracker = [];
                    const start_editing = (e, originalCallback, clicked) => {
                        const form = this.as_form_inline(target, column.form_index, form_field, click_tracker.length);
                        if (form) {
                            this.setState({
                                'inplace_target': {
                                    'form': form,
                                    'field': form_field,
                                    'target': target,
                                    'column': column,
                                    'initialClick': click_tracker.length,
                                },
                            });
                        }
                        click_tracker.length = 0;
                    };
                    props.tabIndex = 0;
                    props.onMouseDown = (e, originalCallback) => {
                        click_tracker.push(true);
                    };
                    props.onFocus = (e, originalCallback) => start_editing(e, originalCallback, false);
                }
            } else {
                const link_function = this.props.link_function || (update => this.default_edit_start(update));
                props.onClick = (e, originalCallback) => {
                    const form = this.as_form(target, column.form_index);
                    link_function({ target, form, table: this, column });
                    return originalCallback ? originalCallback() : true;
                };
            }
        }
        const { form_target } = this.state;
        if (target && form_target && (form_target.type == target.type && form_target.id === target.id)) {
            props.className = classNames(props.className, 'selected');
        }
        if (!dependencies_true(form_field, target)) {
            props.className = classNames(props.className, 'disabled-dependencies');
        }
        props.className = classNames(
            props.className,
            `field-${(form_field && form_field.name) || column.id}`,
            classes.relativePosition,
            (column.field_index === 0 && column.form_index !== 0) && classes.firstField,
        );
        props.key = `f${column.form_index}-${(form_field && form_field.name) || column.id}`;

        if (props.onClick) {
            props.className = classNames(props.className, 'clickable');
            if (!props.title) {
                if (link_only || link_to_edit) {
                    props.title = `View ${target.title || target.type_name || target.name}`;
                } else {
                    props.title = `Click to edit ${target.title || target.type_name || target.name}`;
                }
            }
        }
        return props;
    }
    field_widget = (field, form_index) => {
        /* get the widget for the given field and form

        returns {type_name, Implementation}
        */
        const override = (this.props.widgets && this.props.widgets[field.name]) || field.display_widget || field.field.display_widget;
        // if (override != 'DisplayWidget') {
        //     console.log(`Widget override: ${override}`);
        // }
        var type_name = typeof (override) === 'string' ? override : 'custom';
        var Implementation = type_renderer(override);
        if (override && !Implementation) {
            console.warn(`No display renderer registered for ${override} wanted for field ${field.name}`);
        }
        if (!Implementation) {
            const type_based = field.widget || field.field.widget;
            type_name = type_based;
            Implementation = type_renderer(type_based);
            if (!Implementation) {
                type_name = '';
                Implementation = type_renderer('');
            }
        }
        if (this.props.cellDecorator) {
            Implementation = this.props.cellDecorator(Implementation, this, field, form_index);
        }
        return {
            'type_name': type_name,
            'Implementation': Implementation
        };
    };

    field_accessor = (field, form_index) => {
        /* Return a function that will pull the field's whole value (not just description) from a row */
        const display_key = `${field.name}_display`;
        return (row, rowIndex) => {
            if (field.name == 'parent') {
                // console.log('Accessor: ')
            }
            const target = row[form_index];
            if (target === null || target === undefined) {
                return null;
            }
            if (target[display_key] != undefined) {
                return target[display_key];
            }
            return target[field.name];
        };
    }

    field_renderer(field, form_index) {
        /* If the field has a value-renderer, use that, else use generic renderer */
        const { user } = this.props;
        const form_key = this.props.form_keys[form_index];
        const form = this.props.form_details[form_key];
        const { type_name, Implementation } = this.field_widget(field, form_index);

        const Cell = (props) => {
            const { column } = props;
            const target = props.cell.row.original[column.form_index];
            if (this.inplace_editing(target, column)) {
                return this.state.inplace_target.form;
            }

            var FinalImplementation = Implementation;

            const extra_props = {
                column: props.column,
                ...(field.params || {}),
            };
            // Render-time updates that *should* be triggered by an onSort or the like,
            // *not* by looking at the child's state...
            if (this.props.hierarchy_fields) {
                // By default if we have hierarchy, present with hierarchy...
                const tprops = (this.table && this.table.getResolvedState()) || { 'sorted': [] };
                if (!tprops.sorted.length) {
                    // Only apply hierarchic rendering *iff* we are not sorting by something else
                    if (field.name == this.props.hierarchy_fields.name) {
                        // Needs a special renderer to wrap the default Implementation
                        extra_props.Component = Implementation;
                        extra_props.tree_id = this.props.hierarchy_fields.tree || 'tree';
                        FinalImplementation = TreeNameRenderer;
                    } else if (field.name == this.props.hierarchy_fields.parent) {
                        // This is really just "return null"
                        extra_props.Component = Implementation;
                        FinalImplementation = TreeParentRenderer;
                    }
                } else {
                    console.log(`Non-tree sort is applied ${JSON.stringify(tprops.sorted)}`);
                }
            }


            const record = props.cell.row.original[form_index];
            if (record === null || record === undefined) {
                return '';
            }
            const value = record[field.name];
            // if (!dependencies_true(field,record)) {
            //     return null;
            // }
            const alignment = (props.column.field_index === 0 && props.column.form_index === 0) ? 'left' : 'center';
            const base_field = this.apply_cell_wrapper({
                base: <FinalImplementation
                    key='widget'
                    type_name={type_name}
                    value={value}
                    record={record}
                    field={field}
                    form_details={form}
                    alignment={alignment}
                    {...extra_props}
                />,
                value: value,
                record: record,
                field: field,
                table: this,
                form: form,
                form_key: form_key,
                type_name: type_name,
            });
            if (field && field.name && this.props.field_actions) {
                const actions = this.props.field_actions[field.name];
                if (actions !== undefined && actions !== null) {
                    return <ActionMenu
                        target={record}
                        actions={actions}
                        key='menu'
                    >{base_field}</ActionMenu>;
                }
            }
            return base_field;
        };
        return Cell;
    }
    apply_cell_wrapper = (props) => {
        /* If the react form has filter-gui enabled, wrap the base-form renderer with filter GUI menu 
        
        props have: {base, record, field, value, table, form, form_key, type_name}

        Must return a node to render into the cell of the table,
        normally this should be a wrapper around the base field
        */
        const { base } = props;
        if (this.props.cell_wrapper) {
            return this.props.cell_wrapper(props);
        }
        return base;
    }

    on_quicksetting_click = (form_props, form_key) => {
        const form_index = this.get_form_index(form_key);
        const records = this.sorted_data();
        const rows = records.map(row_info => row_info.original[form_index]);
        const ids = rows.map(r => r.id || r.__pk__);
        const form_targets = ids.map(id => `${id}`).join(',');
        if (form_props.context) {
            form_props.context.targets = ids;
        } else {
            form_props.context = {
                ...this.props.overall_target,
                'targets': form_targets,
            };
        }

        form_props.target = {
            action: 'Edit',
            ...(rows.length ? rows[0] : {}),
            'targets': form_targets,
        };
        console.info(`${form_props.context.targets.length} records selected`);
        return <ReactForm {...form_props} />;
    };
    quick_setting_map = () => {
        return {
            ...(this.props.quick_settings || {}),
            ...(this.props.storage.quick_settings || {}),
        };
    };
    quick_setting_form(form_key, form_field) {
        /* Is there a quick-setting form for the given field */
        const mapping = this.quick_setting_map();
        return mapping[form_field.name];
    }
    quick_setting_trigger(form_key, form_field) {
        /* IFF we have a quicksetting form for field, create a quicksetting trigger for it */
        const qsf = this.quick_setting_form(form_key, form_field);
        if (this.props.editable && qsf) {
            return quick_settings_form(
                qsf,
                `Mass Update ${form_field.label}`,
                form_key || this.props.overall_target.__type__,
                this.props.overall_target, // to allow for constraining selections
                `${form_field.label}`,
                {
                    form: (props) => this.on_quicksetting_click(props, form_key),
                },
            );
        } else {
            return null;
        }
    }
    quick_setting_renderer(form_field, form_index) {
        /* Create a QuickSetting renderer for a field */
        const form_key = this.props.form_keys[form_index];

        if (this.quick_setting_form(form_key, form_field)) {
            return (cellProps) => this.quick_setting_trigger(
                form_key, form_field
            );
        } else {
            return null;
        }
    }
    header_renderer(form_field, form_index) {
        const { classes, user } = this.props;
        const title = form_field.field.help_text;
        if (this.props.editable && (!(form_field.readonly || form_field.field.readonly))) {
            const form_key = this.props.form_keys[form_index];
            const form = this.props.form_details[form_key];
            return (cellProps) => <div className="ft-editable-header" title={title}>
                <div className="ft-field-name">{form_field.label}</div>
            </div>;
        } else {
            return (cellProps) => <div className="ft-header ft-field-name" title={title}>
                {form_field.label}
            </div>;
        }
    }
    should_show(field) {
        if (field.hidden) {
            return false;
        }
        if (this.props.exclude && this.props.exclude.indexOf(field.name) > -1) {
            return false;
        }
        if (this.props.hierarchy_fields && this.props.hierarchy_fields.parent == field.name) {
            // Only show parent if we have a table reference and it says we've applied a sort...
            // Note that this *also* avoids a flash where the parent column is present before
            // we get the table reference...
            if (this.table && this.sorted_data().length) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
    columns = () => {
        var final_columns = [];

        this.props.form_keys.map((form_key, form_index) => {
            // TODO: provide second-level columns for the sub-records if > 1
            var header_columns = final_columns;
            if (this.props.form_titles && this.props.form_titles[form_index]) {
                const header = {
                    'key': `${form_key}-${form_index}`,
                    'Header': this.props.form_titles[form_index],
                    'columns': [],
                };
                final_columns.push(header);
                header_columns = header.columns;
            } else {
                header_columns = final_columns;
            }
            var fields = [];
            if (this.props.title_only_forms && this.props.title_only_forms[form_key]) {
                const tonly = this.props.title_only_forms[form_key];
                if (!tonly.name) {
                    fields = [
                        {
                            'label_id': `title-${form_key}`,
                            'label': form_key,
                            'name': 'title',
                            'field': {
                                'help_text': `Click to edit`,
                                'widget': 'DisplayWidget',
                                'display_widget': 'DisplayWidget',

                            }
                        },
                    ];
                } else {
                    fields = [tonly];
                }
            } else {
                if (!this.props.form_details[form_key]) {
                    console.error(`Form key ${form_key} is declared, but is not in form details`);
                    return;
                }
                fields = [
                    ...this.form_fields_in_order(this.props.form_details[form_key], this.props.field_order),
                    ...((this.props.extra_fields && this.props.extra_fields[form_key]) || [])
                ];
            }
            fields.map((field, field_index) => {
                if (!this.should_show(field)) {
                    return;
                }
                const col = this.field_to_column(field, field_index, form_index, form_key);
                if (col) {
                    header_columns.push(col);
                }
            });
        });
        const { column_callback } = this.props;
        if (column_callback) {
            return column_callback(final_columns, this);
        }
        return final_columns;
    };
    form_fields_in_order = (form_details, field_order=[]) => {
        /* Given a single form's details, get its fields in order */
        if(field_order && field_order.length > 0){
            const result = [];
            const seen = {};
            form_details.fields.map(field => {
                seen[field.name] = field;
            });
            field_order.map(curr_field => {
                    const field = seen[curr_field];
                    if (!field) {
                        console.log(`Field ${curr_field} is declared in the field_order but is not present in the form`);
                        return;
                    }
                    result.push(field);
                    delete seen[curr_field];
                });
            Object.values(seen).map(field => {
                result.push(field);
            });
            return result;
        }
        if (!form_details.field_sets) {
            return form_details.fields;
        }
        const result = [];
        const seen = {};
        form_details.fields.map(field => {
            seen[field.name] = field;
        });
        form_details.field_sets.map(field_set => {
            field_set.fields.map(name => {
                const field = seen[name];
                if (!field) {
                    if (name != 'service_group' && name != 'service_role' && name != 'owner') {
                        // common optional metadata don't want to generate warnings...
                        console.log(`Field ${name} is declared in the field-set ${field_set.key} but is not present in the form`);
                    }
                    return;
                }
                result.push(field);
                delete seen[name];
            });
        });
        Object.values(seen).map(field => {
            result.push(field);
        });
        return result;
    }

    field_to_column = (field, field_index, form_index, form_key) => {
        const col = column_for_field(`${form_index}`, field, {
            editable: this.props.editable,
            Header: this.header_renderer(field, form_index),
            QuickSetting: this.quick_setting_renderer(field, form_index),
            Cell: this.field_renderer(field, form_index),
            form_index: form_index,
            form_key: form_key,
            form_field: field,
            field_index: field_index,
            ...this.field_widget(field, form_index),
        });
        // Override the generic with the simple and specific...
        col.accessor = this.field_accessor(field, form_index);

        const { type_name } = col;
        if (this.props.column_widths && col.form_field && col.form_field.name && this.props.column_widths[col.form_field.name]) {
            // Allow an instance of the table to override column default...
            col.size = this.props.column_widths[col.form_field.name];
        } else if (type_name === 'BooleanField' || type_name === 'CheckboxInput') {
            col.size = 'small';
        } else if (type_name === 'IntegerInput') {
            col.size = 'medium';
        }
        col.sortType = this.sortForColumn(col, field, field_index, form_index, form_key);
        // console.log(`Field ${field.name} => Accessor: ${col.accessor}`);

        if(col.form_field.field.filterWidget=='dropdown'){
            col.Filter = ({column: { filterValue, setFilter, preFilteredRows, form_field, id}}) => {
                const labels = (form_field.params && form_field.params.labels) ? form_field.params.labels : undefined
                // Calculate the options for filtering using the preFilteredRows
                const optionsSet = new Set()
                preFilteredRows.length > 0 && preFilteredRows.forEach(row => {
                    if ((id == 'connected' || id == 'level') && (row.values[id] == undefined)) {
                        optionsSet.add(null)
                    }
                    else if ((row.values[id] != undefined) && row.values[id].toString().length > 0) {
                        optionsSet.add(row.values[id])
                    }
            
                })
                const options = [...optionsSet.values()].sort()
                // Render a multi-select box
                return (
                    <select
                        value={filterValue}
                        onChange={e => {
                            setFilter(e.target.value || undefined)
                        }}
                    >
                        <option value="">All</option>
                        {options && options.map((option, i) => {
                            return(
                                <option key={i} value={String(option)}>
                                    {(labels&&labels[option]) ? labels[option] : option.toString()}
                                </option>    
                                )
                        })}
                    </select>
                )
            }

            col.filter = dropdown_filter_rows;
        }
        else if(col.form_field.field.filterWidget=='timePicker'){
            col.Filter = DateTimeFilterPopper;
            col.filter = customTimeRangeFilter;
        }


        if (col && this.props.columns) {
            return merge_column(col, this.props.columns);
        } else if (col) {
            return col;
        }
    };
    sortForColumn = (column, field, field_index, form_index, form_key) => {
        /* create a sort function for the column that can do the right thing most of the time */
        const collate = new Intl.Collator('en', {
            'sensitivity': 'base',
            'numeric': true,
            'ignorePunctuation': true,
        });
        return (rowA, rowB, columnId, desc) => {
            const [valueA, valueB] = [rowA.values[columnId], rowB.values[columnId]];
            const base = typedValueCompare(valueA, valueB, collate);
            const multiplier = desc ? -1 : 1;
            // return base * multiplier;
            return base;
        };
    };
    sorted_data = () => {
        /* get the sorted/filtered set of records */
        return this.rows;
    };

    default_render_form = (props, table) => {
        const { inPlaceButtons } = this.props;
        return <ReactForm
            buttons={inPlaceButtons}
            {...props}
        />;
    };
    render_form() {
        const finish = (evt) => {
            this.setState({
                'form_target': null,
                'form_details': null,
            });
        };
        const on_render = this.props.onForm || this.default_render_form;
        return on_render({
            target: this.state.form_target,
            form_details: this.state.form_details,
            form_key: (this.state.form_details && this.state.form_details.form_key) || this.state.form_target.__type__,

            onSave: finish,
            onClose: finish,
        }, this);
    }
    onFilterRows = (rows) => {
        /* Callback to get the reference to the underlying react data-table */
        this.rows = rows;
    };

    reload_data = () => {
        if(this.props.storage.periodic){
            this.props.storage.poll()
        }
        else{
            this.props.storage.get(
                this.props.storage.url,
            )
        }
    }

    manualPaginationUpdate = (offset, limit) => {
        this.props.storage.update_extra_context({"offset":offset,"limit":limit})
        this.reload_data()
    }

    filter_data = (key, value) => {
        setTimeout(() => {
            //need to change this to __contains or something similar and add support fo this in the bakckend
            let field_key = `${key}__contains`;
            if(value){
                this.props.storage.update_extra_context({[field_key]:value})   
            }
            else{
                this.props.storage.remove_extra_context(key)
            } 
            this.reload_data()
        }, 500)
    }

    render() {
        if (!(this.props.form_details && this.state.columns)) {
            console.log(`Waiting for loading`);
            return <MuiLoader title="Loading" />;
        }
        // console.log(`Form details ${JSON.stringify(this.props.form_details)}`);
        var data;
        data = this.props.data.map((record) => {
            return this.props.wrap_data(record, this);
        });
        const editing_form = this.state.form_target && this.render_form(this.state.form_target);
        // console.log(`Editable on table: ${this.props.editable}`);

        // if (data.length) {
        //     console.log(`Re-rendering table ${JSON.stringify(data[0])}`);
        // }
        return <div className='react-table-form'>
            {editing_form}
            <ATXTable
                onFilterRows={this.onFilterRows}
                data={data}
                columns={this.state.columns}
                showPagination={false}
                pageSize={this.props.pageSize}
                minRows={0}
                filterable
                useServersideFiltering={this.props.useServersideFiltering}
                total_records={this.props.total_records || 0}
                manualPaginationUpdate={this.manualPaginationUpdate}
                filter_data={this.filter_data}
                downloads={this.props.downloads}
                defaultSorting={this.props.defaultSorting}
                defaultFilterMethod={generic_filter_rows}
                collapseOnDataChange={false}
                defaultFilters={this.props.defaultFilters}
                preference_key={this.props.preference_key}
                preference_default={this.props.preference_default}
                getTdProps={(...args) => this.getTdProps(...args)}
                getTrProps={(...args) => this.getTrProps(...args)}
            />
        </div>;
    }
}

ManualReactFormTable.propTypes = {
    form_details: PropTypes.object.isRequired,
    form_keys: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,

    editable: PropTypes.bool,
    widgets: PropTypes.object,
    record_key: PropTypes.func,
    form_titles: PropTypes.array,
    title_only_forms: PropTypes.object,
    onForm: PropTypes.func,
    extra_fields: PropTypes.object,
    column_widths: PropTypes.object,
    quick_settings: PropTypes.object,
    hierarchy_fields: PropTypes.shape({
        parent: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        tree: PropTypes.string, // shouldn't this be an integer?
    }),
    link_function: PropTypes.func,
    link_to_edit: PropTypes.bool,
    overall_target: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    defaultFilters: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    })),
    column_callback: PropTypes.func,
    wrap_data: PropTypes.func,
};


function column_for_field(base_accessor, form_field, props) {
    /* given a form-field, produce a react-table column description */
    const column = {
        form_field: form_field,
    };
    column.Header = form_field.label;
    column.id = form_field.name;
    if (form_field && form_field.field.__type__ == '') {
        column.name = form_field.name;
    }
    // const display_form = (form_field.field && form_field.field.choices) ? `${form_field.name}_display` : form_field.name;
    const display_form = form_field.name;
    if (typeof base_accessor === 'string') {
        if (!base_accessor.length) {
            column.accessor = display_form;
        } else {
            column.accessor = `${base_accessor}.${display_form}`;
        }
    } else if (Array.isArray(base_accessor)) {
        column.accessor = [...base_accessor, display_form];
    } else {
        column.accessor = (row) => {
            var base_row = base_accessor(row);
            return base_row[form_field.name];
        };
    }
    return {
        ...column,
        ...props
    };
}

const typedValueCompare = (valueA, valueB, collate = null) => {
    let cmp = 0;
    if (valueA === null || valueA === undefined) {
        if (valueB === null || valueB === undefined) {
            return 0;
        } else {
            return -1;
        }
    } else if (valueB === null || valueB === undefined) {
        return 1;
    }
    if (typeof valueA == typeof valueB) {
        if (typeof valueA == 'number') {
            cmp = valueB > valueA ? -1 : (
                valueA > valueB ? 1 : 0
            );
        } else if (typeof valueA == 'string') {
            // Special cases first...
            if (valueA.match(/^\d+$/) && valueB.match(/^\d+$/)) {
                return typedValueCompare(parseInt(valueA), parseInt(valueB));
            } else if (valueA.match(/^\d+\.\d+\.\d+\.\d+$/) && valueB.match(/^\d+\.\d+\.\d+\.\d+$/)) {
                return typedValueCompare(valueA.split(/\W+/), valueB.split(/\W+/));
            }
            if (collate) {
                cmp = collate.compare(valueA, valueB);
            } else {
                cmp = valueB.toLowerCase() > valueA.toLowerCase() ? -1 : (
                    valueA.toLowerCase() > valueB.toLowerCase() ? 1 : 0
                )
            }
        } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
            for (let i = 0; i < valueA.length && i < valueB.length; i++) {
                let valueCmp = typedValueCompare(valueA[i], valueB[i], collate);
                if (valueCmp != 0) {
                    cmp = valueCmp;
                    break
                }
            }
            if (cmp == 0) {
                /* Longer arrays with the same prefix are "bigger" */
                cmp = typedValueCompare(valueA.length, valueB.length, collate);
            }
        } else if (valueA.toISOString && valueB.toISOString) {
            cmp = typedValueCompare(valueA.toISOString(), valueB.toISOString(), collate);
        } else if (valueA.title !== undefined && valueB.title !== undefined) {
            cmp = typedValueCompare(valueA.title, valueB.title, collate);
        } else if (valueA.name !== undefined && valueB.name !== undefined) {
            cmp = typedValueCompare(valueA.name, valueB.name, collate);
        } else {
            console.log(`Do not have sorting rule for ${valueA} vs ${valueB}`);
        }
    }
    // console.log(`Cmp ${JSON.stringify(valueA)} ${JSON.stringify(valueB)} => ${cmp}`);
    return cmp;

};
const BaseReactFormTable = withRouter(WithCurrentUser(
    withStyles(styles)(
        ManualReactFormTable
    )
));

const ReactFormTable = MultiTemplated(
    BaseReactFormTable
);

export {
    column_for_field,
    BaseReactFormTable,
    ReactFormTable,
    requiring_tabular_storage
};
export default ReactFormTable;
