import React from 'react';
import { EditIntent } from 'editintent';

class QuickSelectSetting extends React.Component {
    static defaultProps = {
        choices: null,
        title: 'Mass Update',
        key: null,
        form_key: null,
        field: null,
        subset: null,
        setter: null,
        getter: null,
        target: {},
        object_signals: null,
    };
    handleUpdate(evt) {
        var value = evt.target.value;
        if (value === '') {
            return;
        }
    }
    render() {
        return <div className="quick-setting-group" key={this.props.key}>
            <ReactInlineForm
                key={this.props.key}
                form_key={this.props.form_key}
                field={this.props.field}
                debug={true}
                target={this.props.target}
                object_signals={this.props.object_signals}
            />
        </div>;
    }
}

class QuickSettingsHeader extends React.Component {
    render() {
        var quick_settings_shown = this.props.detailState();
        return <tr
            key="quick-settings-header"
            className={'quick-settings-header' + (quick_settings_shown ? '' : ' hidden')}
        >
            <th />
            <th >
                <QuickSelectSetting
                    key='mass-enable'
                    form_key='MassSourceEnable'
                    field='enabled'
                />
            </th>
            <th />
            <th />
            <th />
            <th >
                <QuickSelectSetting
                    key='mass-aspect-ratio'
                    form_key='MassAspectRatioControl'
                    field='aspect_ratio_control'
                />
            </th>
            <th >
                <QuickSelectSetting
                    key='mass-audio-channels'
                    form_key='MassAudioChannelsControl'
                    field='audio_channels'
                />
            </th>
            <th >
                <QuickSelectSetting
                    key='mass-audio-volume'
                    form_key='MassAudioVolumeControl'
                    field='audio_volume'
                />
            </th>
            <th >
                <QuickSelectSetting
                    key='mass-v-channel'
                    form_key='MassChannelAssignment'
                    field='v_channel'
                />
            </th>
            <th >
            </th>
        </tr>;
    }
}

var quick_settings_form = function (
    form_key,
    help_text = 'Update range of settings at one time',
    type = null,
    target = null,
    type_name = null,
    props = {}
) {
    const standin_target = {
        'type_name': 'Quick Settings',
        '__pk__': null,
        '__type__': type || form_key,
    };
    return <EditIntent
        icon='magic'
        label={null}
        action='Mass Update'
        context={target}
        help_text={help_text}
        form_key={form_key}
        target={standin_target}
        load_form_options={{ use_post: true }}
        {...props}
    />;
};

export {
    QuickSettingsHeader,
    QuickSelectSetting,
    quick_settings_form,
};
