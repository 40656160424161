/* HOC that loads Form Definitions from the server for each form display */
import React from 'react';
import { ErrorList } from 'errorlist';
import PropTypes from 'prop-types';
import { stores } from 'storeregistry';
import { POST_SIGNAL } from "storages/ajaxobservables";
import { store_by_key } from 'formprovider';

const ServerLoaded = (WrappedComponent) => {
    class ServerLoaded extends React.Component {
        state = {
            form_key: null,
            storage: null,
            target: null,
            context: null,
            form_details: null,
            load_errors: null,
            load_form_options: {}
        };
        constructor(props) {
            super(props);
            this.mounted = true;
        }
        safeSetState = (state) => {
            if (this.mounted) {
                return this.setState(state);
            }
            return null;
        };
        componentDidMount() {
            if (!this.props.form_details) {
                // console.log(`Loading form details`);
                this.loadFormDetails();
            }
            /* Allow updates even if the original was not loaded from server */
            POST_SIGNAL.listen(this.on_post_signal);
        }
        componentWillUnmount() {
            this.mounted = false;
            POST_SIGNAL.ignore(this.on_post_signal);
        }
        on_post_signal = () => {
            // console.debug(`Reloading form on post-signal`);
            if (this.mounted) {
                this.loadFormDetails();
            }
        };
        getFormKey = () => {
            if (this.props.form_key) {
                return this.props.form_key;
            }
            return this.props.target.__type__ || this.props.target.type;
        };
        /* Retrieve form details from the server */
        loadFormDetails = () => {
            var target = this.props.target;
            var storage = (this.props.storage || stores.forms);
            if (typeof storage == 'string') {
                storage = store_by_key(storage);
            }
            // console.log(`Storage: ${storage}`);

            var form_key = this.getFormKey();
            // console.log(`Loading form details ${form_key}`);
            if (form_key == undefined || form_key == null) {
                return this.on_load_failure(new Error('No form key currently available, likely logged out'))
            }
            return storage.get_form(
                form_key,
                target.__pk__ || target.id,
                this.props.context,
                this.props.load_form_options
            ).then(
                this.on_load_result
            ).catch(
                this.on_load_failure
            );
        };
        on_load_result = (result) => {
            const form_key = this.getFormKey();
            var storage = (this.props.storage || stores.forms);
            if (result && result.form) {
                // console.log(`Loaded form ${form_key} from ${storage.url}`);
                this.safeSetState({ 'form_details': result.form, 'errors': null, 'editable': result.editable });
            } else {
                console.warn(`Failure loading form ${form_key} from storage ${storage.url}`);
                if (this.handleError) {
                    this.handleError(null, `Failure loading form ${form_key} from storage ${storage.url}`);
                } else {
                    this.baseHandleError(result);
                }
                window.setTimeout(() => this.loadFormDetails(), 5000);
            }
        };
        on_load_failure = (err) => {
            const form_key = this.getFormKey();
            var storage = (this.props.storage || stores.forms);
            console.warn(`Failure loading form ${this.getFormKey()} from storage ${storage.url}`);
            if (this.handleError) {
                this.handleError(err, 'Loading form description');
            } else {
                this.baseHandleError(err);
            }
            window.setTimeout(() => {
                this.loadFormDetails();
            }, 5000);
        };

        baseHandleError = (error) => {
            /* If we have no other error handler, handle our errors */
            if (error.message) {
                this.safeSetState({
                    'errors': [error.message],
                });
            } else if (error.messages) {
                this.safeSetState({
                    'errors': error.messages,
                });
            } else {
                this.safeSetState({
                    'errors': [`Unable to load form, will retry ${JSON.stringify(error)}`],
                });
            }

        };
        render() {
            var details = this.state.form_details || this.props.form_details;
            var editable = this.state.editable === undefined ? this.props.editable : this.state.editable;
            if (details) {
                return <WrappedComponent {...this.props} form_details={details} editable={editable} />;
            } else if (this.state.errors) {
                return <ErrorList errors={this.state.errors} />;
            } else {
                return <div className="spinner form-loading"></div>;
            }
        }
    }
    ServerLoaded.propTypes = {
        // storage: PropTypes.oneOf([PropTypes.shape({
        //     get_form: PropTypes.func,
        //     url: PropTypes.string,
        // }), PropTypes.string]),
        form_key: PropTypes.string,
        target: PropTypes.shape({
            __type__: PropTypes.string,
            type: PropTypes.string,
            id: PropTypes.number,
        }),
        context: PropTypes.object,
        form_details: PropTypes.object,
    };
    return ServerLoaded;
};

export default ServerLoaded;
