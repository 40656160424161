/* On import, registers and exposes all of the dash-specific widget types */
import React from 'react';
import { widget } from 'reactform/widget';
import './renderers';

import DirectSmallFile from 'reactform/DirectSmallFile';
import FileUploadSelect from 'reactform/FileUploadSelect';
import FileUploadTrigger from 'reactform/FileUploadTrigger';
import BigFileUpload from './BigFileUpload';
import StreamingFileUpload from './StreamingFileUpload';

import CheckboxWidget from './CheckboxWidget';
import DateTimeWidget from './DateTimeWidget';
import DisplayWidget from './DisplayWidget';
import ArrayDisplayWidget from './ArrayDisplayWidget';
import DownloadLink from './DownloadLink';
import EmailInput from './EmailInput';
import NumberInputWidget from './NumberInputWidget';
import { MHzWidget, KHzWidget, MbpsWidget, KbpsWidget } from './NumberInputWidget';
import PasswordInputWidget from './PasswordInputWidget';
import SelectMultiple from './SelectMultiple';
import SelectWidget from './SelectWidget';
import TextAreaWidget from './TextAreaWidget';
import TextInputWidget from './TextInputWidget';
import ToggleButtonWidget from './ToggleButtonWidget';
import URLLinkPopup from './URLLinkPopup';
import URLWidget from './URLWidget';
import RangeSelect from './rangeselect';
import ComboBoxWidget from './ComboBoxWidget';
import SelectionFromListWidget from './SelectionFromListWidget';
import MomentTimezoneWidget from './MomentTimezoneWidget';
import LineupWidget from './LineupWidget';
import PromoteChangeWidget from './PromoteChangeWidget';
import { ShortWithTitleWidget } from './shortwithtitle';
import TransferListWidget from './TransferListWidget';
import ServiceBindingsWidget from './servicebindingwidget';
import { SelectOrCreateWidget } from 'dash/SelectOrCreate';
import EmailsFromJsonWidget from './EmailsFromJsonWidget';
import ColorPickerWidget from './ColorPickerWidget';
import GradientPickerWidget from './GradientPickerWidget';
// import JSONEditorWidget from './JSONEditorWidget';

const CheckboxWidgetFactory = widget('CheckboxInput', CheckboxWidget);
const DateTimeInputFactory = widget('DateTimeInput', DateTimeWidget);
const DateTimeWidgetFactory = widget('DateTimeWidget', DateTimeWidget);
const DirectSmallFileFactory = widget('DirectSmallFile', DirectSmallFile);
const DownloadLinkFactory = widget('DownloadLink', DownloadLink);
const EmailInputFactory = widget('EmailInput', EmailInput);
const EmailsFromJsonWidgetFactory = widget('EmailsFromJsonWidget', EmailsFromJsonWidget);
const ColorPickerWidgetFactory = widget('ColorPickerWidget', ColorPickerWidget);
const GradientPickerWidgetFactory = widget('GradientPickerWidget', GradientPickerWidget);
const FileUploadSelectFactory = widget('FileUploadSelect', FileUploadSelect);
const FileUploadTriggerFactory = React.createFactory(FileUploadTrigger);
const BigFileUploadFactory = widget('ClearableFileInput', BigFileUpload);
widget('BigFileUpload', BigFileUpload);
widget('StreamingFileUpload', StreamingFileUpload);
widget('DirectSmallFile', DirectSmallFile);
widget('FileUploadTrigger', FileUploadTrigger);
widget('FileUploadSelect', FileUploadSelect);
widget('ComboBox', ComboBoxWidget);
widget('MHz', MHzWidget);
widget('KHz', KHzWidget);
widget('Mbps', MbpsWidget);
widget('Kbps', KbpsWidget);
widget('SelectionFromListWidget', SelectionFromListWidget);
widget('MomentTimezoneWidget', MomentTimezoneWidget);
widget('LineupWidget', LineupWidget);
widget('PromoteChange', PromoteChangeWidget);
widget('ShortWithTitle', ShortWithTitleWidget);
widget('TransferList', TransferListWidget);
widget('ServiceBindings', ServiceBindingsWidget);
widget('SelectOrCreate', SelectOrCreateWidget);
// widget('JSONEditor', JSONEditorWidget);

const NumberWidgetFactory = widget('NumberInput', NumberInputWidget);
const PasswordInputWidgetFactory = widget('PasswordInput', PasswordInputWidget);
const SelectMultipleFactory = widget('SelectMultiple', SelectMultiple);
const SelectWidgetFactory = widget('Select', SelectWidget);
const TextAreaWidgetFactory = widget('Textarea', TextAreaWidget);
const TextInputWidgetFactory = widget('TextInput', TextInputWidget);
const ToggleButtonWidgetFactory = widget('ToggleButton', ToggleButtonWidget);
const URLLinkPopupFactory = widget('URLLinkPopup', URLLinkPopup);
const URLWidgetFactory = widget('URLInput', URLWidget);

const DisplayWidgetFactory = widget('DisplayWidget', DisplayWidget);
const ArrayDisplayWidgetFactory = widget('ArrayDisplayWidget', ArrayDisplayWidget);
const ChoiceRangeSelectFactory = widget('ChoiceRangeSelect', RangeSelect);

export {
    CheckboxWidget,
    CheckboxWidgetFactory,
    BigFileUploadFactory,

    DateTimeWidget,
    DateTimeWidgetFactory,
    DateTimeInputFactory,
    DirectSmallFile,
    DirectSmallFileFactory,
    DisplayWidget,
    DisplayWidgetFactory,
    DownloadLink,
    DownloadLinkFactory,
    EmailInputFactory,
    EmailInput,
    EmailsFromJsonWidgetFactory,
    EmailsFromJsonWidget,
    ColorPickerWidgetFactory,
    ColorPickerWidget,
    GradientPickerWidgetFactory,
    GradientPickerWidget,
    FileUploadSelect,
    FileUploadSelectFactory,
    FileUploadTrigger,
    FileUploadTriggerFactory,
    BigFileUpload,
    NumberInputWidget,
    NumberWidgetFactory,
    PasswordInputWidget,
    PasswordInputWidgetFactory,
    SelectMultiple,
    SelectMultipleFactory,
    SelectWidget,
    SelectWidgetFactory,
    TextAreaWidget,
    TextAreaWidgetFactory,
    // JSONEditorWidget,
    TextInputWidget,
    TextInputWidgetFactory,
    ToggleButtonWidget,
    ToggleButtonWidgetFactory,
    URLLinkPopup,
    URLLinkPopupFactory,
    URLWidget,
    URLWidgetFactory,
    widget,
};
