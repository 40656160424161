import React, { useEffect, useState } from "react";
import "./vsbbthemepreview.css";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import JSZip from "jszip";
import MuiLoader from "dash/MuiLoader";
import { ErrorList } from 'errorlist';
import { Box, Typography } from "@material-ui/core";

const VsbbThemePreview = (props) => {
    const [imageUrls, setImageUrls] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const loadImages = async (icons) => {
            let urls = {};
            const previewSvgs = ["VolMed.svg"];
            JSZip.loadAsync(icons)
                .then(function (zip) {
                    Object.keys(zip.files).forEach(function (filename) {
                        zip.files[filename]
                            .async("string")
                            .then(function (fileData) {
                                if (previewSvgs.includes(filename)) {
                                    urls[filename] = fileData;
                                }
                            });
                    });
                    setImageUrls(urls);
                })
                .catch(function (err) {
                    setError(["Error in loading icons.zip, kindly check the file format"]);
                    console.error("Error reading zip file:", {...err});
                });
        };
        setLoading(true);
        props.cssMap["icons"] && loadImages(props.cssMap["icons"]);

        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [props.cssMap["icons"]]);

    if (loading) {
        return <MuiLoader />;
    }

    if (error) {
        return <ErrorList errors={error}/>;
    }

    const newFont = props.cssMap["font"]
        ? new FontFace(
              "customFont",
              `url(${URL.createObjectURL(
                  props.cssMap["font"]
              )}) format('truetype')`
          )
        : null;
    let fontFamily = "inherit";
    if (newFont) {
        newFont.load().then((loadedFont) => {
            document.fonts.add(loadedFont);
        });
        fontFamily = "customFont";
    }

    const InfoOverlay = () => {
        return (
            <div className="info-overlay-holder">
                <div className="info-overlay">
                    <div className="info-overlay-left-column">
                        <div className="channel-box">
                            <div className="channel-box-content">
                                <div className="channel-number">11</div>
                                <div className="channel-label">TEST</div>
                            </div>
                            <div className="channel-box-mirror">
                                <div className="channel-box-mirror-content">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="info-overlay-right-column">
                        <div className="program-info-stack">
                            <div className="program-info-header">
                                <div className="current-program">
                                    <span className="program-time-start">
                                        15:15 PM
                                    </span>
                                    -
                                    <span className="program-time-end">
                                        16:16 PM
                                    </span>
                                    {"\t"}
                                    <div className="spacer" />
                                    <span className="program-title">
                                        Program1
                                    </span>
                                </div>
                            </div>

                            <div className="program-details">
                                <div className="search-matches">
                                    <div className="search-match">
                                        <div className="channel-id">T-123</div>
                                        <div className="station-name">
                                            Channel 5
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="time-logo-stack">
                            <div className="clock-holder">
                                <div className="current-time">10:10 AM</div>
                                <div className="current-date">
                                    Tue, 11 Jun, 2024
                                </div>
                            </div>

                            <div className="flex-spacer"></div>

                            <div className="operator-logo-holder">
                                {props.cssMap["operator_icon"] && (
                                    <img
                                        src={URL.createObjectURL(
                                            props.cssMap["operator_icon"]
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const MenuComponent = () => {
        return (
            <div className="menu-columns" id="previewWrapper">
                <div className="menu">
                    <div className="operator-logo-holder">
                        {/* <!-- OperatorLogo --> */}
                        {/* {imageUrls["icons/Back.svg"] && <img src={`data:image/svg+xml;base64,${btoa(imageUrls['icons/Back.svg'])}`} alt="hey" />} */}
                        {props.cssMap["operator_icon"] && (
                            <img
                                src={URL.createObjectURL(
                                    props.cssMap["operator_icon"]
                                )}
                            />
                        )}
                        {/* <img src="/var/firmware/protected/uploads/2/e8fb89ed-70a3-4c92-a1d2-03af57761279" /> */}
                    </div>
                    <div className="title">Menu Title</div>
                    <div className="options">
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Import-Export </div>
                            {/* {
                    imageUrls["icons/MenuRight.svg"] &&
                    <div className="marker Menu">
                        <img src={`data:image/svg+xml;base64,${btoa(imageUrls['icons/MenuRight.svg'])}`} alt="hey" />
                    </div>
                } */}
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Local Mode </div>
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Diagnostics </div>
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Room Number </div>
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">
                                User Settings Operation{" "}
                            </div>
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Channel Plan </div>
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Reboot </div>
                        </div>
                        <div className="option">
                            {/* <div className="spacer" /> */}
                            <div className="label Menu">Factory Default </div>
                        </div>
                    </div>
                </div>
                <InfoOverlay />
                <div className="volume-overlay-holder">
                    <div className="volume-control">
                        <div className="range" style={{ minHeight: "60%" }}>
                            <div className="slot">
                                <div
                                    className="bar"
                                    style={{ bottom: "60%" }}
                                />
                            </div>
                            <div className="textual">60%</div>
                        </div>
                        <div className="audio-level-icon">
                            {/* <div className="icon mute-icon" aria-label="Audio Muted" /> */}
                            {/* <div className="icon vol-low-icon" aria-label="Volume Low" /> */}
                            <div
                                className="icon vol-med-icon"
                                aria-label="Volume Medium"
                                style={{
                                    backgroundImage:
                                        imageUrls["VolMed.svg"] &&
                                        `url(data:image/svg+xml;base64,${btoa(
                                            imageUrls["VolMed.svg"]
                                        )}`,
                                }}
                            />
                            {/* <div className="icon vol-high-icon" aria-label="Volume High" /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const EpgComponent = () => {
        const times = [
            { time: "6:00\tAM", left: "0%", width: "20.4%" },
            { time: "6:30\tAM", left: "20.4%", width: "20.4%" },
            { time: "7:00\tAM", left: "40.4%", width: "20.4%" },
            { time: "7:30\tAM", left: "60.4%", width: "20.4%" },
            { time: "8:00\tAM", left: "80.4%", width: "20.4%" },
        ];

        const epgBody = [
            {
                title: "ABC",
                channel: "22",
                station: "ABC",
                highlight: true,
                programs: [
                    {
                        title: "The Ultimate Fitness Challenge",
                        left: "0%",
                        width: "20.3%",
                        highlight: true,
                        showIndicator: true,
                    },
                    {
                        title: "The Wildlife Documentary Hour",
                        left: "20.5%",
                        width: "20.3%",
                    },
                    {
                        title: "Mastering the Art of Home Improvement: From Basic Repairs to Advanced Renovations",
                        left: "40.9%",
                        width: "20.3%",
                    },
                    {
                        title: "Adventures in Space Exploration",
                        left: "61.3%",
                        width: "20.3%",
                    },
                    {
                        title: "Mastering Home DIY Projects",
                        left: "81.7%",
                        width: "20.4%",
                    },
                ],
            },
            {
                title: "TMP",
                channel: "23",
                station: "TMP",
                programs: [
                    {
                        title: "The Incredible Adventures of Professor Whiskers and the Time-Traveling Explorers Club",
                        left: "0%",
                        width: "61.1%",
                    },
                    {
                        title: "Fashion Trends and Tips",
                        left: "61.4%",
                        width: "40.8%",
                    },
                ],
            },
            {
                title: "XYZ",
                channel: "24",
                station: "XYZ",
                programs: [
                    {
                        title: "Epicurean Delights: World Cuisine",
                        left: "0%",
                        width: "20.3%",
                    },
                    {
                        title: "Gourmet Delights: An Epic Culinary Journey Through the World's Most Exotic Kitchens",
                        left: "20.5%",
                        width: "61.2%",
                    },
                    {
                        title: "Historical Legends and Mythical Tales: Exploring the World's Most Fascinating Stories",
                        left: "81.8%",
                        width: "40.8",
                    },
                ],
            },
        ];
        return (
            <div className="epg">
                <div className="epg-main">
                    <div className="top-spacer" />
                    <div className="epg-header">
                        <div className="page-title">Guide</div>
                        <div className="times">
                            {times.map((time, index) => {
                                return (
                                    <div
                                        className="time"
                                        style={{
                                            width: time.width,
                                            left: time.left,
                                        }}
                                        key={`time-${index}`}
                                    >
                                        <div className="time-display">
                                            <span className="time-display">
                                                <span className="tz-time">
                                                    {time.time}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* <div className="loading-notice" style="display: none;">Loading...</div> */}
                    <div className="epg-body EpgBody">
                        {epgBody.map((epg, index) => {
                            return (
                                <div
                                    className={`epg-row network-station EpgBody ${
                                        epg.highlight && "highlight"
                                    }`}
                                    title={epg.title}
                                    style={{ height: "88px" }}
                                    key={`epg-${index}`}
                                >
                                    <div className="station EpgBody">
                                        <div
                                            className="network-icon EpgBody"
                                            // style='background-image: url("objects/");'
                                        ></div>
                                        <div className="channel EpgBody">
                                            {epg.channel}
                                        </div>
                                        <div className="station-name EpgBody">
                                            {epg.title}
                                        </div>
                                    </div>
                                    <div className="schedules EpgBody">
                                        {epg.programs.map((program, index) => {
                                            return (
                                                <div key={`program-${index}`}>
                                                    <div
                                                        className={`schedule EpgBody ${
                                                            program.highlight &&
                                                            "highlight"
                                                        }`}
                                                        style={{
                                                            left: program.left,
                                                            width: program.width,
                                                        }}
                                                    >
                                                        <div className="program EpgBody">
                                                            <div className="title EpgBody">
                                                                {program.title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {program.showIndicator && (
                                                        <div
                                                            className="cur-min-indicator EpgBody"
                                                            style={{
                                                                left: "20vw",
                                                                zIndex: 10,
                                                            }}
                                                        >
                                                            ▼
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="flex-spacer EpgBody"></div>
                    </div>
                </div>
                <InfoOverlay />
            </div>
        );
    };

    const previewComponents = [
        { name: "MAIN MENU", component: <MenuComponent /> },
        { name: "EPG MENU", component: <EpgComponent /> },
    ];

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === previewComponents.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? previewComponents.length - 1 : prevIndex - 1
        );
    };

    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <div
                className="preview-wrapper"
                style={{
                    ...props.cssMap["style"],
                    fontFamily: fontFamily,
                    marginTop: "2rem",
                    height: props.cssMap["height"],
                    width: props.cssMap["width"],
                }}
            >
                {previewComponents[currentIndex]["component"]}
            </div>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                marginTop={3}
            >
                <ChevronLeftRoundedIcon
                    onClick={handlePrev}
                    style={{ cursor: "pointer" }}
                />
                <Typography gutterBottom style={{ marginLeft: 4 }}>
                    {previewComponents[currentIndex]["name"]}
                </Typography>
                <ChevronRightRoundedIcon
                    onClick={handleNext}
                    style={{ marginLeft: 4, cursor: "pointer" }}
                />
            </Box>
        </Box>
    );
};

export default VsbbThemePreview;
