import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { generic_filter_rows } from 'reactform/genericfilter';
import concrete_columns from './concretecolumns';

import theme from 'dash/theme';
import { useTable, useSortBy, useFilters, usePagination, useExpanded } from 'react-table';
import useGUIState from 'dash/guistate';
import CssBaseline from '@material-ui/core/CssBaseline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PreferenceMenu from './atxtablepreferencemenu';
import "./atx-react-table.css";
import PatientTextField from 'patienttextfield';

import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import Input from '@material-ui/core/Input';

import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { TableFooter } from '@material-ui/core';
import Papa from 'papaparse';
import apply_accessor from 'dash/charts/accessor';
import { saveAs } from 'file-saver';
import { ArrowDropDown } from '@material-ui/icons';
import { ArrowRight } from '@material-ui/icons';
import with_error_boundary from 'errorboundary';
import { DBPrefContext } from '../dash/userprefs';

const styles = (theme) => ({
    clickableRow: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
            cursor: 'pointer',
        },
    },
    relativeHolder: {
        position: 'relative',
    },
    header: {
        backgroundColor: theme.roles.headers.table,
    },
    expansionCell: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: 'initial',
        padding: '.15rem',
    },
    paginationWrapper: {
        backgroundColor: theme.roles.headers.table,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: theme.palette.grey[600],
        fontSize: 'smaller',
        display: 'flex',
    },
    paginationPrevious: {
        flex: 1,
        textAlign: 'left',
    },
    paginationNext: {
        flex: 1,
        textAlign: 'right',
    },
    paginationSummary: {
        flex: 4,
        textAlign: 'center',
    },
    pageHeading: {
        fontWeight: 'bold',
    },
    paginationRows: {
        paddingTop: '.5em',
    },
    pageCount: {
    },
    pageSizePreference: {
        flex: 1,
        fontWeight: 'lighter',
    },
    currentPage: {
        fontWeight: 'bold',
    },
    inputRight: {
        textAlign: 'right',
    },
    headerGroup: {
        backgroundColor: theme.roles.headers.tableGroup,
    },
    headerCell: {

    },
    bodyCell: {
        verticalAlign: 'top',
    },
    sortIndicator: {
        position: 'absolute',
        top: '-9px',
        right: '-3px',
        color: 'green',
        fontSize: 'smaller',
    },
    paddedColumnHeader: {
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
    },
    quickSettingFloat: {
        position: 'absolute',
        top: '1px',
        left: '2px',
        color: 'black',
        '&:hover': {
            color: 'blue',
        },
    },
    filterField: {
        fontSize: '.75em',
        color: theme.palette.grey[400],
    },
    firstField: {
        borderLeft: 'thin solid #e0e0e0',
    },
    quick_setting_trigger: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    quickSettingTh: {
        textAlign: 'center',
        backgroundColor: theme.roles.headers.table,
        padding: 0,
        margin: 0,
        '& .edit-intent a': {
            display: 'block',

        }
    },
    defaultAlignment: {
        alignContent: 'start',
        justifyContent: 'center',
    },
    root: {
        '&.ReactTable .rt-resizer': {
            width: 12,
            right: -6,
        },
    },
    holder: {

    },
});

const DelayInput = (props) => {
    /* Render an input that only sends changed after a delay 
    
    delay -- time in milliseconds to delay 
    onChange -- callback to receive the event
    
    */
    const { delay = 500, onChange, ...childProps } = props;
    const [pagerTimer, setPagerTimer] = React.useState(null);
    return <Input
        {...childProps}
        onChange={e => {
            e.persist();
            if (pagerTimer) {
                window.clearTimeout(pagerTimer);
            }
            setPagerTimer(window.setTimeout(() => {
                return (onChange && onChange(e));
            }, delay));
        }}
    />
};
DelayInput.displayName = 'DelayInput';


function DefaultColumnFilter({
    column: { id, filterValue, preFilteredRows, setFilter },
    filter_data,
    useServersideFiltering
}) {
    const count = preFilteredRows.length;
    return <PatientTextField value={filterValue} onChange={(value) => {
        setFilter(value)
        // useServersideFiltering && filter_data(id, value)
    }} delay={Math.min(100 + count, 500)} />;
}

const ExpandWidget = (props) => {
    const callback = useMemo(() => {
        return (evt) => props.toggleRowExpanded(props.row);
    }, [props.row])
    if (!props.expanded) {
        return <IconButton onClick={callback} title='Edit Details' aria-label='Edit Details' size="small" disabled={props.canExpand ? true : false}><ArrowRight /></IconButton>;
    } else {
        return <IconButton onClick={callback} title='Edit Details' aria-label='Hide Details' aria-expanded size="small"><ArrowDropDown /></IconButton>;

    }
};

const ExpandedRow = with_error_boundary((props) => {
    /* Render a single expanded row into the table */
    const { row, isExpanded, renderExpanded, columnCount, keyFunction = defaultKeyFunction, renderExpandedRaw = false } = props;
    const { original } = row;
    const key = keyFunction(original);
    const expanded = isExpanded(original);

    // console.log(`Check ${keyFunction(original)} expanded=${expanded}`);
    let expandedRow = null;
    if (expanded) {
        if (renderExpandedRaw) {
            return renderExpanded(row.original, row, columnCount)
        }
        expandedRow = <TableRow
            key={`body-r-expanded-${key}`}
        >
            <TableCell >

            </TableCell>
            <TableCell colSpan={columnCount} key={`expanded-cell-${key}`}>
                {renderExpanded(row.original, row, columnCount)}
            </TableCell>
        </TableRow>
    }
    return expandedRow
});
ExpandedRow.displayName = 'ExpandedRow';

const defaultKeyFunction = (row) => {
    /* Default key is to look for __key__ or __pk__ in the orginal record */
    if (row.original) {
        row = row.original;
    }
    return row.__key__ || row.__pk__;
}

const RenderATXTable = withStyles(styles)(useGUIState(React.forwardRef((props, ref) => {
    const {
        columns,
        data,
        classes,
        defaultSorting,
        showPagination,
        filterable,
        onFilterRows,
        downloadCallback,
        defaultFilters = null,
        renderExpanded = null, // (record, row, columnCount) => display to render in a full-width column under a row
        renderExpandedRaw = false, // if true, renderExpanded should return a <TableRow> in this table, rather than a view...
        keyFunction = defaultKeyFunction,
        useServersideFiltering = false,
        manualPaginationUpdate = null,
        filter_data = null,
        total_records = 0,
        ...childProps
    } = props;

    React.useEffect(() => {
        getAllColumnNameInOrder(columns)
    }, [columns])

    const preferences = React.useContext(DBPrefContext);
    const [current, setState] = React.useState(null);
    // const [pagerTimer, setPagerTimer] = React.useState(null);
    const [expanded, setExpanded] = React.useState({});
    const [onDragCalled, setOnDragCalled] = React.useState(false);
    const [currentColumns, setCurrentColumns] = React.useState(null);

    const toggleExpanded = (row) => {
        let key = keyFunction(row);
        if (key !== null && key !== undefined) {
            if (expanded[key]) {
                delete expanded[key];
            } else {
                expanded[key] = true;
            }
            console.log(`Setting ${key} to expanded`);
            setExpanded({ ...expanded });
        }
    };
    console.log(`Table render`);
    const isExpanded = (row) => {
        let key = keyFunction(row);

        return !!(expanded[key]);
    };

    const preference_key = props.preference_key;
    let initialGUIState = {}, updateGUIState;
    if (props.useGUIState) {
        [initialGUIState, updateGUIState] = props.useGUIState();
        // console.log(`Initial GUI state ${JSON.stringify(initialGUIState)}`);
    }
    const initialState = initialGUIState || {
    };
    if (defaultSorting && initialState.sortBy === undefined) {
        initialState.sortBy = defaultSorting;
    }
    let initialStateFilters = {};
    if (defaultFilters) {
        initialStateFilters['filters'] = defaultFilters;
    }
    const extraPlugins = [
        usePagination
    ].filter(x => !!x);

    let initialPageSize = (initialGUIState && initialGUIState.pageSize) || props.pageSize || 200;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        allColumns,
        rows,
        prepareRow,

        // Pagination operations
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable(
        {
            columns,
            data: data || [],
            ...(useServersideFiltering && {
                manualPagination: true,
                pageCount: Math.ceil(total_records / initialPageSize),
                total_records: total_records
            }),
            initialState: {
                ...initialState,
                ...initialStateFilters,
                pageSize: initialPageSize,
                pageIndex: props.pageIndex || 0,
            },
            autoResetPage: false,
            autoResetExpanded: false,
            autoResetGroupBy: false,
            autoResetSelectedRows: false,
            autoResetSortBy: false,
            autoResetFilters: false,
            autoResetRowState: false,
            useExpanded: !!renderExpanded,
            useControlledState: (state) => {
                return {
                    ...state,
                    hiddenColumns: (initialState && initialState.hiddenColumns) || [],
                };
            },
            useServersideFiltering: useServersideFiltering,
            filter_data: filter_data,
            manualPaginationUpdate: manualPaginationUpdate,
        },
        useFilters,
        useSortBy,
        ...extraPlugins
    );
    const try_render_cell = (props) => {
        const { cell } = props;
        try {
            return cell.render('Cell', cell);
        } catch (e) {
            return `Error rendering ${cell}: ${e}`;
        }
    };

    function getAllColumnNameInOrder(columns) {
        const allColumnsInOrder = [];
        columns.map((column) => {
            allColumnsInOrder.push(column.id);
        })
        setCurrentColumns(allColumnsInOrder);
    }


    const changeColumnOrder = (preference_key, updatedColumnOrder) => {

        const isKeyPresent = (obj = {}, preference_key) => {
            const objKeys = Object.keys(obj);
            for (let key of objKeys) {
                if (key === preference_key) {
                    return true;
                }
            }
            return false;
        }

        const { save, ...restPrefs } = preferences;
        const isFieldOrderPresent = isKeyPresent(preferences?.preferences?.fieldOrder, preference_key);
        let fieldOrderCopy = { ...preferences?.preferences?.fieldOrder };

        if (isFieldOrderPresent) {
            fieldOrderCopy = { ...fieldOrderCopy, [preference_key]: updatedColumnOrder }
        }
        else if (!isFieldOrderPresent) {
            fieldOrderCopy[preference_key] = updatedColumnOrder;
        }
        const run_save = () => save({
            ...restPrefs,
            preferences: {
                ...(preferences.preferences || {}),
                fieldOrder: fieldOrderCopy
            },
        });
        window.setTimeout(run_save, 1);
    }

    const handleDrop = (e, columnName) => {

        const draggedColumnName = e.dataTransfer.getData('columnName');
        const indexOfDropColumnName = currentColumns.indexOf(columnName);
        const indexOfDragColumnName = currentColumns.indexOf(draggedColumnName);

        if (indexOfDragColumnName !== indexOfDropColumnName && !onDragCalled) {
            const newColumns = [...currentColumns];
            const [draggedColumn] = newColumns.splice(indexOfDragColumnName, 1);
            newColumns.splice(indexOfDropColumnName, 0, draggedColumn);
            changeColumnOrder(props.preference_key, newColumns);
        }
        setOnDragCalled(true);
    }

    const handleDragStart = (e, columnName) => {
        setOnDragCalled(false);
        e.dataTransfer.setData('columnName', columnName);
    }

    const handleDragOver = (e, columnIndex, columnName) => {
        e.preventDefault();
    };

    /* Yech, but we need to know this from outside */
    onFilterRows(rows);
    let columnCount = 0;

    React.useEffect(() => {
        if (useServersideFiltering) {
            manualPaginationUpdate(pageIndex * pageSize, pageSize)
        }
    }, [pageIndex, pageSize])

    return <div className={classNames(classes.relativeHolder, 'prefs-holder', props.wrapperHolder)}>
        <Table {...getTableProps()} ref={ref} className='ReactTable atx-table' >
            <TableHead className={classNames(classes.root, 'rt-thead', '-headerGroups')}>
                {headerGroups.map((headerGroup, headerGroupIndex) => {
                    const have_filters = headerGroup.headers.filter(column => column.canFilter).length;
                    const are_sortable = headerGroup.headers.filter(column => (!column.columns)).length;
                    const have_quicksettings = headerGroup.headers.filter(column => (!!column.QuickSetting)).length;
                    const row_props = headerGroup.getHeaderGroupProps();
                    const rows = [];
                    const names = <TableRow
                        {...row_props}
                        className={classNames(
                            are_sortable ? classes.header : classes.headerGroup,
                            'rt-tr',
                            are_sortable ? 'names' : 'groups',
                            are_sortable ? classes.clickableRow : null,
                            row_props.className,
                        )}
                        key={`rt-thead-${headerGroupIndex}`}
                    >
                        {!!renderExpanded ? <TableCell
                            key="expanded"
                            className={
                                classNames(
                                    classes.headerCell,
                                    classes.expansionCell,
                                    'rt-th',
                                )
                            }
                        >&nbsp;</TableCell> : null}
                        {headerGroup.headers.map((column, index) => {
                            const columnName = headerGroup.headers[index].id;
                            const can_sort = (!column.columns) && !(column.sortable === false);
                            const cell_props = column.getHeaderProps(column.getSortByToggleProps());
                            columnCount += 1;

                            return <TableCell
                                {...cell_props}
                                className={
                                    classNames(
                                        classes.headerCell,
                                        'rt-th',
                                        classes.relativeHolder,
                                        cell_props.className,
                                        column.size ? `col-size-${column.size}` : null,
                                        (column.sortable || column.QuickSetting) ?
                                            classes.paddedColumnHeader : null,
                                    )
                                }
                                draggable
                                onDragStart={(e) => handleDragStart(e, columnName)}
                                onDragOver={(e) => handleDragOver(e)}
                                onDrop={(e) => handleDrop(e, columnName)}
                            >
                                {column.render('Header')}
                                {
                                    can_sort &&
                                    <div className={classes.sortIndicator}>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? '▼'
                                                : '▲'
                                            : ''}
                                    </div>
                                }
                                {
                                    (column.QuickSetting) &&
                                    <div className={classes.quickSettingFloat}>
                                        {column.render('QuickSetting')}
                                    </div>
                                }
                            </TableCell>;
                        })}
                    </TableRow>;
                    rows.push(names);
                    if (have_filters && filterable) {
                        const filter_row_props = headerGroup.getHeaderGroupProps();

                        rows.push(
                            <TableRow
                                {...filter_row_props}
                                className={classNames('rt-tr filters', classes.header)} key='filters-${headerGroupIndex}'
                            >
                                {!!renderExpanded ? <TableCell
                                    key="expanded"
                                    className={
                                        classNames(
                                            classes.headerCell,
                                            classes.expansionCell,
                                            'rt-th',
                                        )
                                    }
                                /> : null}
                                {headerGroup.headers.map(column => {
                                    return <TableCell
                                        {...column.getHeaderProps()}
                                        className={classNames(
                                            classes.header,
                                            'rt-th',
                                            column.size ? `col-size-${column.size}` : null,
                                        )}>
                                        {
                                            (column.canFilter) &&
                                            column.render('Filter', column)
                                        }
                                    </TableCell>;
                                })}
                            </TableRow>
                        );
                    }
                    return rows;
                })}

            </TableHead>
            <TableBody {...getTableBodyProps()}>
                {(page || rows).map((row, i) => {
                    prepareRow(row);
                    const special_props = props.getTrProps ? props.getTrProps(null, row, {}) : {};
                    const row_props = row.getRowProps();
                    const expandedRow = (!!renderExpanded) ? <ExpandedRow
                        row={row}
                        isExpanded={isExpanded}
                        renderExpanded={renderExpanded}
                        columnCount={columnCount}
                        keyFunction={keyFunction}
                        renderExpandedRaw={props.renderExpandedRaw}
                    /> : null;
                    let expanded = false;
                    if (!!renderExpanded) {
                        expanded = isExpanded(row.original)
                    }
                    return <React.Fragment key={`wrapper-row-${i}`}>
                        <TableRow
                            {...special_props}
                            {...row_props}
                            className={classNames(
                                row_props.className,
                                special_props.className,
                                'rt-tr',
                                i % 2 ? '-even' : '-odd'
                            )}
                            key={`body-r-${i}`}
                        >
                            {!!renderExpanded ? <TableCell
                                key="expanded"
                                className={
                                    classNames(
                                        classes.headerCell,
                                        classes.expansionCell,
                                        'rt-th',
                                    )
                                }
                            >
                                <ExpandWidget expanded={expanded} toggleRowExpanded={toggleExpanded} row={row.original} />
                            </TableCell> : null}


                            {row.cells.map(cell => {
                                const custom_props = (props.getTdProps && props.getTdProps(
                                    null,
                                    cell.row,
                                    cell.column,
                                    cell.original,
                                )) || {};
                                return (
                                    <TableCell
                                        {...cell.getCellProps()}
                                        {...custom_props}
                                        className={classNames(
                                            custom_props.className,
                                            classes.bodyCell,
                                            'rt-td',
                                            cell.column.size ? `col-size-${cell.column.size}` : null,
                                            `col-${cell.column.id}`,
                                            cell.column.form_field ? `field-${cell.column.form_field.name}` : null,
                                        )}
                                    >
                                        {try_render_cell({ cell })}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {expandedRow}
                    </React.Fragment>;
                })}
            </TableBody>
        </Table>
        {(showPagination || useServersideFiltering) && <div className={classes.paginationWrapper}>
            <div className={classes.paginationPrevious}>
                <IconButton size="small" disabled={!canPreviousPage} title="Previous Page" onClick={previousPage}>
                    <SkipPreviousIcon color={canPreviousPage ? "secondary" : "disabled"} />
                </IconButton>
            </div>
            <div className={classes.paginationRows}>
                <span className={classes.rowCount}>
                    {useServersideFiltering ? (
                        <>
                            {total_records || 0} Record
                            {total_records != "1" ? "s" : ""}
                        </>
                    ) : (
                        <>
                            {(rows && rows.length) || 0} Record
                            {rows && rows.length != 1 ? "s" : ""}
                        </>
                    )}
                </span>
            </div>
            <div className={classes.paginationSummary}>
                <span className={classes.pageHeading}>Page:</span>
                <DelayInput
                    disabled={(pageCount <= 1)}
                    classes={{
                        input: classes.inputRight,
                    }}
                    min='1'
                    max={`${pageCount}`}
                    type="number"
                    defaultValue={pageIndex + 1}
                    style={{ maxWidth: '4em' }}
                    title="Jump to a page"
                    onChange={e => {
                        const page = e.target.value ? Number.parseInt(e.target.value) - 1 : 0;

                        gotoPage(page);
                    }}
                />
                <span className={classes.currentPage}>{pageIndex + 1}</span>
                /
                <span className={classes.pageCount}>{Math.max(pageCount, 1)}</span>
            </div>
            <div className={classes.pageSizePreference}>
                <Input
                    classes={{
                        input: classes.inputRight,
                    }}
                    key={`pagesize-preference-${pageSize}`}
                    type="number"
                    min='5'
                    max='500'
                    defaultValue={pageSize} style={{ maxWidth: '4em' }} title="Set number of records/page"
                    onChange={e => {
                        const count = e.target.value ? Number.parseInt(e.target.value) : 0;
                        if (count >= 5 && count <= 500) {
                            setPageSize(count);
                            // Callback to set the preference value...
                            updateGUIState({
                                ...initialGUIState,
                                pageSize: count,
                            })
                        }
                    }}
                />
                {' records/page'}
            </div>
            <div className={classes.paginationNext}>
                <IconButton size="small" disabled={!canNextPage} title="Next Page" onClick={nextPage}>
                    <SkipNextIcon color={canPreviousPage ? "secondary" : "disabled"} />
                </IconButton>
            </div>
        </div>}
        {
            preference_key && <PreferenceMenu
                allColumns={allColumns}
                updateGUIState={updateGUIState}
                initialState={initialState}
                hideColumnCallback={(newGUIState) => {
                    setState({});
                }}
                downloadCallback={downloadCallback}
                preference_key={props.preference_key}
            />
        }
    </div>;
})));
RenderATXTable.displayName = 'RenderATXTable';


class BaseATXTable extends React.Component {
    static defaultProps = {
        pageSize: 200,
        showPagination: false,
        minRows: 0,
        filterable: true,
        defaultFilterMethod: generic_filter_rows,
        defaultSorting: null,
        defaultFilters: null,
        collapseOnDataChange: false,
        defaultCell: null,
        keyField: '__pk__',
        className: null,
        useServersideFiltering: false,
        manualPaginationUpdate: null,

        renderExpanded: null, // (record, row, columnCount) => display to render in a full-width column under a row
        renderExpandedRaw: false, // if true, renderExpanded should return a <TableRow> in this table, rather than a view...

        columns: null, /* Custom feature in column declarations (beyond react-table):

            size -- one of the col-size-tiny, col-size-small, col-size-short, col-size-medium, col-size-large, col-size-expanded, col-size-wide
        
        */
    }
    rows = [];
    getFinalColumns = () => {
        const { defaultCell, columns } = this.props;
        if (defaultCell && columns) {
            concrete_columns(columns).map(column => {
                if (!column.Cell) {
                    column.Cell = defaultCell;
                }
            });
        }
        concrete_columns(columns).map(column => {
            if (!column.Filter) {
                column.Filter = DefaultColumnFilter;
            }
            if (!column.filter) {
                column.filter = this.props.defaultFilterMethod;
            }
        });
        /* Unique column ids are a requirement in react-table 7.x */
        const seen = {};
        concrete_columns(columns).map(column => {
            const original = column.id;
            if (column.id) {
                let count = 1;
                while (seen[column.id]) {
                    count += 1;
                    column.id = `${original}-${count}`;
                }
                seen[column.id] = column;
            }
        });
        return columns;
    }
    overRideFunctions = (props) => {
        /* Create functions that do overrides for our common bits */
        const {
            data,
            ...final_props
        } = props;

        ['getTheadTrProps', 'getTheadFilterProps', 'getTrGroupProps'].map(key => {
            if (props[key]) {
                final_props[key] = (state, rowInfo) => {
                    // console.log(`Callback on ${key} with child def`);
                    return this[key](state, rowInfo, props[key](state, rowInfo));
                };
            } else {
                final_props[key] = (state, rowInfo) => {
                    // console.log(`Callback on ${key} with no child def`);
                    return this[key](state, rowInfo, {});
                };
            }
        });
        if (data && data.length > final_props.pageSize) {
            final_props.showPagination = true;
        }
        return {
            // getTheadTrProps: this.getTheadTrProps,
            // getTheadFilterProps: this.getTheadFilterProps,
            // getTrGroupProps: this.getTrGroupProps,
            onFilterRows: this.onFilterRows,
            getTheadGroupProps: this.getTheadGroupProps,
            getTrProps: this.getTrProps,
            getTdProps: this.props.getTdProps,
            data,
            ...final_props,
        };
    }
    getTheadGroupProps(state, rowInfo, column, instance) {
        const { classes } = this.props;
        return {
            className: classNames(classes.header),
        };
    }
    getTheadTrProps = (state, rowInfo, baseprops) => {
        /* add standard header properties for ATXTable */
        const { classes } = this.props;
        return {
            ...baseprops,
            className: classNames((baseprops && baseprops.className), classes.header),
        };
    }
    getTheadFilterProps = (state, rowInfo, baseprops) => {
        const { classes } = this.props;
        return {
            ...baseprops,
            className: classNames(baseprops.className, classes.header),
        };
    }
    getTrProps = (state, rowInfo, baseProps) => {
        const target = rowInfo.original;
        const base = this.props.getTrProps ? this.props.getTrProps(state, rowInfo, baseProps) : {};
        const key = target[this.props.keyField];
        return {
            ...base,
            key: key,
            ...baseProps,
        };
    }
    getTrGroupProps = (state, rowInfo, baseProps) => {
        /* Use keyField to set stable keys on row */
        const target = rowInfo.original;
        return {
            key: target[this.props.keyField] || rowInfo.index,
        };
    }
    onFilterRows = (rows) => {
        /* Callback to get the reference to the underlying react data-table */
        this.rows = rows;
        if (this.props.onFilterRows) {
            this.props.onFilterRows(rows);
        }
    }
    onDownloadCSV = (evt) => {
        evt.stopPropagation();
        const formatted = this.formatCSV()
        const blob = new Blob([formatted], { type: "text/csv;charset=utf-8" });
        saveAs(blob, `table-download.csv`);
    }
    formatCSV = (evt) => {
        /* Request to download the CSV of the currently displayed rows */
        console.warn(`Should download now`);
        let data = this.props.data;
        if (this.rows && this.rows.length) {
            data = this.rows;
        }
        let columns = this.getFinalColumns().filter(col => !!col.accessor);
        if (!columns.length) {
            let columnsarray = this.getFinalColumns().map(val => val.columns);
            columns = columnsarray.flat().filter(col => !!col.accessor);
        }
        const get_name = (value, type_name) => {
            if (value === null || value === undefined) {
                return null;
            }
            if (Array.isArray(value)) {
                return value.map(get_name);
            }
            if (type_name == 'TimestampDate') {
                const date = new Date();
                date.setTime(value * 1000);
                value = date;
            }
            if (value.toUTCString) {
                return value.toUTCString();
            }
            if (typeof value === 'object') {
                if (value.title) {
                    return value.title;
                }
                if (value.name) {
                    return value.name;
                }
            }
            return value;
        }
        const rows = data.map((row, rowIndex) => {
            return columns.map(col => {
                if (typeof col.accessor == 'function') {
                    return get_name(col.accessor(row, rowIndex), col.type_name);
                } else {
                    return get_name(apply_accessor(row, col.accessor), col.type_name);
                }
            });
        });
        const col_name = (column) => {
            if (column.form_field) {
                return column.form_field.label;
            }
            else if (typeof column.Header == 'string') {
                return column.Header;
            } else {
                return column.id;
            }
        }
        return Papa.unparse({
            data: rows,
            fields: columns.map(col_name),
        }, {
            header: true,
            quotes: true,
        })
    }
    render() {
        const props = this.overRideFunctions(this.props);
        const columns = this.getFinalColumns();
        const final_props = {
            ...props,
            columns: columns,
            className: classNames(
                props.className,
                'atx-table',
                this.props.classes.root,
            ),
            wrapperHolder: this.props.classes.holder,
            getTrProps: this.getTrProps,
            downloadCallback: this.props.downloads ? this.onDownloadCSV : null,
        };
        // {...final_props}
        const ref_func = this.props.tableRef || this.table_ref_callback;

        return <RenderATXTable
            ref={ref_func}
            {...final_props}
        />;
    }
}
const ATXTable = withStyles(styles)(BaseATXTable);
ATXTable.displayName = 'ATXTable';

class SelectingATXTable extends React.Component {
    static defaultProps = {
        keyField: '__pk__',
    }
    state = {
        selected: {},
    }
    on_row_click = (record) => {
        const key = record[this.props.keyField];
        const selected = {
            ...this.state.selected,
        };
        if (selected[key]) {
            delete selected[key];
        } else {
            selected[key] = record;
        }
        this.setState({
            selected: selected,
        });
        if (this.props.on_change) {
            this.props.on_change(selected);
        }
    }
    // <IconButton title="Select all" onClick={(evt) => {
    //     const new_selections = {};
    //     const instance = this.get_current_vct();
    //     instance.vct.map((channel,i)=>{
    //         new_selections[i] = channel;
    //     });
    //     this.setState({'vct_selections': new_selections});
    // }}><Icon>select_all</Icon></IconButton>
    // <IconButton title="Clear all" onClick={(evt) => {
    //     this.setState({'vct_selections':{}});
    // }}><Icon>clear_all</Icon></IconButton>

    getTrProps = (state, rowInfo, baseProps) => {
        const { classes } = this.props;
        const target = rowInfo.original;
        const key = target[this.props.keyField];
        const base = (
            this.props.getTrProps && this.props.getTrProps(state, rowInfo, baseProps)
        ) || (
                {}
            );
        const selected = this.state.selected[key] ? 'selected' : null;
        return {
            key: key,
            ...base,
            className: classNames(base.className, selected),
            onClick: () => this.on_row_click(target),
        };
    }
    render() {
        return <ATXTable {...this.props} getTrProps={this.getTrProps} />;
    }
}

export default ATXTable;
export { ATXTable, SelectingATXTable };
