import React, { useState } from "react";
import { null_load, with_target } from "dash/resolvetarget";
import EditingCard from "dash/editingcard";
import { ReactForm, form_action } from "reactform";
import MuiLoader from "dash/MuiLoader";
import VsbbThemePreview from "shogun/vsbbthemepreview.js";

const GetThemePreviewProps = (form) => {
    let val = form.currentFormValues();
    let mystyle = {};
    Object.keys(val)
        .filter(
            (objKey) =>
                ![
                    "icons",
                    "font",
                    "operator_icon",
                    "service_group",
                    "uploader_type",
                    "hash",
                    "height",
                    "width",
                ].includes(objKey)
        )
        .map((key) => {
            let newKey = key.replace(/_/g, "-");
            mystyle[`--${newKey}`] = val[key];
        });
    return {
        style: { ...mystyle },
        icons: val["icons"],
        font: val["font"],
        operator_icon: val["operator_icon"],
        height: val["height"],
        width: val["width"],
    };
};

const BaseIPGThemeEditor = (props) => {
    let { target, __type__, __pk__, storage, ...childProps } = props;

    const [togglePreview, settogglePreview] = useState(false);
    const [cssMap, setCssMap] = useState({});

    var preview = form_action({
        key: "preview",
        name: togglePreview ? "update preview" : "preview",
        icon: "eye",
        help_text: "Preview the theme",
        skipSave: true,
        callback: function (form, action) {
            const cssValues = GetThemePreviewProps(form);
            setCssMap(cssValues);
            settogglePreview(true);
        },
    });

    if (!(null_load(props) || target)) {
        return <MuiLoader />;
    }

    let buttons = [
        "save",
        "delete",
        (form) =>
            form.currentValue("uploader_type") == "THEME_CUSTOMIZER" &&
            preview.render(form),
    ];

    return (
        <React.Fragment>
            <EditingCard
                title={target.title}
                actions={[]}
                key={`detail-${target.key}`}
            >
                <ReactForm
                    {...childProps}
                    target={target}
                    use_dialog={false}
                    buttons={buttons}
                    storage={"shogunconf_forms"}
                />
                {togglePreview && <VsbbThemePreview cssMap={cssMap} />}
            </EditingCard>
        </React.Fragment>
    );
};
const IPGThemeView = with_target(BaseIPGThemeEditor);

export default IPGThemeView;
export { GetThemePreviewProps, IPGThemeView };
