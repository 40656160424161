import $ from 'fakequery';
import React from 'react';

import WidgetRender from '../widgets/custom_widget_render';
import { human_bytes } from "humanize";
import { ClassyWidget } from 'reactform/classywidget';
import { fa_icon } from 'faicon';

class DirectSmallFile extends ClassyWidget {
    handleFileChange = (evt) => {
        $.map(evt.target.files, (file) => {
            this.handleChange(file, true);
        });
    }
    render() {
        const { show_label } = this.props;
        var current = this.current_value();
        const field = this.get_field();
        if (!current) {
            /* only if we don't have a currently selected value */
            current = this.widget_data('current');
        }
        var current_view = null;
        if (current) {
            if (current.url) {
                current_view = <div className="current-file" key="current"><a className="download flat-icon" href={current.url}>{fa_icon('globe')} {current.name}</a></div>;
            } else {
                current_view = <div className="current-file" key="current">{current.name} ({human_bytes(current.size)})</div>;
            }
        }
        return <div className="direct-small-file">
            <WidgetRender form_field={field} widget_class="select-widget" show_label={show_label} >
                {current_view}
                <input type="file" className="small-file" onChange={this.handleFileChange}></input>
            </WidgetRender>
        </div>;
    }
}

export default DirectSmallFile;